<template>
  <div>
    <Navbar />
    <main class="flex-1">
      <div class="py-4">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div class="py-4">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white"><!--  border border-gray-300 rounded-lg -->
              <slot/>
            </div>
          </div>
          <!-- /End replace -->
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>
<script setup>

useHead({
  titleTemplate: (title) => {
    return title ? `${title}` : 'Sewmee';
  },
});
</script>