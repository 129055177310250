export const useNotificationStore = () => {
    const runtimeConfig = useRuntimeConfig()
    const userStore = useUserStore()
    const stateKey = 'notificationsState'
    const state = useState(stateKey, () => {
      return {
        initialized: false,
        notifications: [],
      }
    })

    const reloadNotifications = async () => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        const { data, pending, error, refresh } = await useFetch(`${runtimeConfig.public.apiBase}/api/notifications/shop`, {
            headers: {Authorization: `Bearer ${userStore.getToken()}`},
            method: 'GET'
        });
        if (error.value) {
            error = null;
            data = null;
            userStore.logoutHandler()
            navigateTo('/signin')
        } else if ('payload' in data.value && '_errors' in data.value.payload) {
            state.value.initialized = false;
            // error = null;
            // data = null;
            return false
        } else {
            state.value.notifications = Array.isArray(data.value.payload) ? data.value.payload : []
            state.value.initialized = true;
            // error = null;
            // data = null;
            return true
        }      
    }

    const getNotifications = () => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return []
        }
        return state.value.notifications
    }

    const getUncheckedCount = () => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return 0
        }
        return state.value.notifications.reduce((prv, cur) => {
            return (cur.checked == 0) ? prv + 1 : prv
        }, 0)
    }

    const applyChecked = async (target, id) => {
        if (!userStore.isLoggedIn()) {
            state.value.initialized = false;
            return false
        }
        await useFetch(`${runtimeConfig.public.apiBase}/api/notifications/${id}`, {
            headers: {Authorization: `Bearer ${userStore.getToken()}`},
            method: 'POST',
            body: {},
        });
    }

    return {
        reloadNotifications,
        notifications: state.value.notifications,
        getNotifications,
        getUncheckedCount,
        applyChecked,
    }
}